/*****************************************************************************
 * Import
 *****************************************************************************/
import { Box } from "@mui/material";

/*****************************************************************************
 * Component
 *****************************************************************************/
export default function Header () {
  return (
    <Box />
  );
}
